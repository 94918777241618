import { nextTick } from "vue";

export default {
  props: {
    modelValue: {},
    fields: {
      type: Object,
      default: {},
    },
    inputSize: {
      type: [Boolean, String, Number],
      default: false,
    },
    /**
     * Prevent class attr from being automatically set. This causes visual issue when used within FormInput
     */
    class: {
      type: String,
      default: "",
    },
    name: {
      type: [String, Boolean],
      default: false,
    },
    errorMessage: {
      type: [String, Array],
      default: "",
    },
  },
  data: function () {
    return {
      newLocaleAddValue: null,
      //  validationModel: {},
      errorFixed: false,
      addingNewItems: false,
      addingItemsTimeout: null,
      addingItemsTimeoutLength: 600,
      isEnforcingIntegrity: false,
      hasAddError: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    hasError() {
      return this.errorMessage !== "";
    },
    uniqueId() {
      return utilities.getUniqueNumber();
    },
    translatedLocales() {
      if (!this.value || typeof this.value !== "object") {
        return [];
      }

      return Object.keys(this.value);
    },
    allLocales() {
      return Object.values(this.getLocaleObjects());
    },
    hasUntranslatedLocales() {
      let result = false;
      this.allLocales.forEach((availableLocale) => {
        if (!this.translatedLocales.includes(availableLocale.tag)) {
          result = true;
          return false;
        }
      });
      return result;
    },
    untranslatedLocalesList() {
      return this.allLocales
        .map((locale) => {
          return { value: locale.tag, label: locale.labelFull };
        })
        .filter((locale) => !this.translatedLocales.includes(locale.value));
    },
  },
  created() {},
  methods: {
    enforceValueIntegrity() {
      if (this.isEnforcingIntegrity) {
        return;
      }

      this.isEnforcingIntegrity = true;

      let isFieldDeleted = false;

      let newValue = this.value ? this.value : {};

      for (const [index, item] of Object.entries(newValue)) {
        let clean = { ...item };

        for (const field of Object.keys(item)) {
          if (!Object.keys(this.fields).includes(field)) {
            delete clean[field];
            isFieldDeleted = true;
          }
        }
        newValue[index] = clean;
      }

      if (isFieldDeleted) {
        this.value = newValue;
      }

      utilities.inTwoTicks(() => {
        this.isEnforcingIntegrity = false;
      });
    },
    async addRow() {
      if (!this.newLocaleAddValue || this.newLocaleAddValue === "") {
        this.$nextTick(() => {
          this.hasAddError = true;
        });
        return;
      }
      if (this.translatedLocales.includes(this.newLocaleAddValue)) {
        this.$s.ui.notification(
          "core.entity.translations.localeAlreadyTranslatedNotification"
        );
        return false;
      }

      clearTimeout(this.addingItemsTimeout);
      this.addingNewItems = true;
      let row = {};

      for (const [key, field] of Object.entries(this.fields)) {
        row[key] = field.defaultValue || null;
      }

      // if parent has delived us nothing, encofce value integrity
      if (typeof this.value === 'undefined') {
        this.value = {};
        // we must wait for the parent to tick, so our change is updated. value becomes {} only on next tick
        await this.$nextTick();
      }


      this.value[this.newLocaleAddValue] = row;
      this.newLocaleAddValue = null;
      utilities.setClientTimeout(() => {
        try {
          this.addingNewItems = false;
        } catch (e) {
          //
        }
      }, this.addingItemsTimeoutLength);

      this.setDefaultValueToAddTranslationField();
    },
    removeRow(index) {
      delete this.value[index];
      if ( ! this.newLocaleAddValue){
        this.setDefaultValueToAddTranslationField();
      }
    },
    getItemClean(input) {
      let item = Object.assign({}, input);

      if (typeof item._id !== "undefined") {
        delete item._id;
      }

      // filter invalid fields
      let final = {};
      for (const field of Object.keys(item)) {
        if (Object.keys(this.fields).includes(field)) {
          final[field] = item[field];
        }
      }

      return final;
    },
    getLocaleLabel(slug) {
      let location = this.getLocaleObjects()[slug];

      if (!location) {
        return "-";
      }

      return this.getLocaleObjects()[slug].labelFull;
    },
    setDefaultValueToAddTranslationField () {
      if (this.untranslatedLocalesList.length > 0) {
        this.newLocaleAddValue = this.untranslatedLocalesList[0].value;
      }
    },
  },
  emits: ["update:modelValue"],
  mounted() {
    this.setDefaultValueToAddTranslationField();
  },
  watch: {
    value: {
      handler: "enforceValueIntegrity",
      deep: true,
      immediate: true,
    },
    newLocaleAddValue() {
      this.hasAddError = false;
    },
  },
};
