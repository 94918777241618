<template>
  <div
    v-sortable="{ onUpdate: onReorder, handle: '[data-role=handle]' }"
    class="repeatable-wrapper panel bg-gray-1 padding-l">
    <div
      v-for="(item, index) of safeValue"
      :key="uniqueId + '-row-' + index"
      class="panel padding-l margin-l-bottom animate--fast"
      :class="{
        animate__fadeIn: addingNewItems && index == valueLength - 1,
      }"
      :cleanItem="getItemClean(item)">
      <div class="item-wrapper">
        <h5 class="margin-remove">
          {{
            translateTitleCase("core.entity.translations.localeTitle", {
              locale: getLocaleLabel(index),
            })
          }}
        </h5>
        <hr class="margin-m-top margin-l-bottom" />
        <div class="item">
          <div class="fields-grid margin-inline-end width-expand">
            <div class="item-fields-wrapper flex flex-wrap gap-m width-expand">
              <div
                v-for="(subItem, subIndex) of getItemClean(item)"
                :key="uniqueId + '-cell-' + index"
                class="field-immediate-wrapper animate--fast"
                :style="fields[subIndex]?.repeatable?.fullWidth ? 'flex: 0 0 100%' : ''"
                :class="{
                  animate__flipInX: addingNewItems && index == valueLength - 1,
                  hidden: fields[subIndex]?.type === 'hidden',
                }"
                :subItem="subItem">
                <form-input
                  v-bind="fields[subIndex]"
                  :id="name + index + subIndex"
                  :model-value="value[index][subIndex]"
                  @field:changed="val => value[index][subIndex] = val"
                  :name="name + '-' + index + '-' + subIndex"
                  :validation="fields[subIndex]?.validation"
                  :do-validations="true"
                  :type="fields[subIndex]?.type" />
              </div>
            </div>
          </div>

          <button-group class="flex gap-xs row-buttons-container">
            <form-button
              icon="x"
              theme="danger"
              :slim="true"
              :show-slot="false"
              @click.stop.prevent="removeRow(index)"></form-button>
          </button-group>
        </div>
      </div>
    </div>

    <div class="repeatable-field-footer">
      <div v-if="hasUntranslatedLocales" class="flex flex-end flex-center ">
        <form-input
          :errorMessage="hasAddError ? t('core.entity.translations.selectLocaleForAddError') : ''"
          v-model="newLocaleAddValue"
          type="select2"
          :list="untranslatedLocalesList"
          :translate-list="false"></form-input>
        <form-button icon="plus" type="primary" @click.prevent="addRow">
          <span v-if="safeValue && valueLength > 0">{{
            translate("core.form.repeatable.addAnother")
          }}</span>
          <span v-else>{{ translate("core.form.repeatable.addFirst") }}</span>
        </form-button>
      </div>
      <div v-if="!hasUntranslatedLocales" class="flex flex-end">
        <p>
          {{
            translate("core.entity.translations.allTranslationsCompleteExplain")
          }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import TranslationsInputMixin from "@/client/extensions/mixins/baseComponents/form/controls/input/translationsInput.js";

export default {
  mixins: [TranslationsInputMixin],
  setup(props) {},
  data() {
    return {};
  },
  computed: {
    safeValue() {
      if (!this.value || typeof this.value !== "object") {
        return {};
      }

      return this.value;
    },
    valueLength() {
      return Object.keys(this.safeValue).length;
    },
  },
  watch: {},
  methods: {
    // custom input class implementation
    getInputClass(type) {
      let inputClass = [];

      if (this.inputSize && typeof this.inputSize === "string") {
        inputClass.push("form-width-" + this.inputSize);
      }

      // error class
      if (this.hasError || false) {
        inputClass.push("form-danger");
      }

      // error fixed indicator
      if (this.errorFixed || false) {
        inputClass.push("form-success");
      }

      return inputClass;
    },
  },
};
</script>

<style scoped lang="scss">
.item-fields-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding-inline-end: var(--margin-l);

  @media (max-width: 1024px) {
    padding-inline-end: var(--margin-m);
  }
}

.field-immediate-wrapper {
  flex: 0 0 calc(50% - (var(--margin-s)));
  min-width: 200px;

  @media (max-width: 1024px) {
    flex-grow: 500;
    flex: 150px;
    min-width: 0;
  }
}

.item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;

  @media (max-width: 767px) {
    flex-direction: column-reverse; // buttons on top, instead of on the side
    gap: var(--margin-m);

    .row-buttons-container {
      width: 100%;

      *:first-child {
        margin-inline-start: auto;
      }
    }
  }
}

.row-buttons-container {
  margin-top: 27px;
  @media (max-width: 767px) {
    margin-top: 0;
    width: 100%;

    *:first-child {
      margin-inline-start: auto;
    }
  }
}
</style>
